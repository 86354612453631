$bg_shade1: #666; /* BG of body */
$bg_shade2: #263238; /* BG of header */
$bg_shade3: #39454B; /* BG of table headers & breadcrumb */
$bg_shade4: #CCC; /* BG of panels */
$bg_shade5: #AAA; /* BG of slide-in-modal */
$bg_shade6: #9e9e9e; /* BG of select */

$txt_shade2: #FFF; /* color of header */
$txt_shade3: #EEE; /* color of table headers & breadcrumb */

body {
  background: $bg_shade1;
}

/* ******* HEADER ****** */
.page-header {
  background: $bg_shade2; /* sidebar BG */
  color: $txt_shade2;

  .breadcrumb-line:not([class*="bg-"]) {
    background: $bg_shade3;
    border-top-color: $bg_shade1;
    color: $txt_shade3;
  }

  .breadcrumb-elements > li > a,
  .breadcrumb > li + li::before,
  .breadcrumb > li > a {
    color: $txt_shade3;
  }
}


/* ****** GENERIC ***** */
.panel {
  background: $bg_shade4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.48);

  .panel-heading {
    background: $bg_shade3;
    color: $txt_shade3;
  }
}

/* ****** Datatable ***** */
.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #DDD;
}

.datatable-search {
  [name="datatable[search]"] {
    background: none;
  }
}

.view-action .panel {
  .panel-tab-content,
  .panel-tab-content.datatable {
    background: transparent;

    .table-xxs thead {
      background: $bg_shade3;
      color: $txt_shade3;
    }
  }
}

.datatable {
  .table {
    thead, tbody, tfoot {
      > tr {
        & > th,
        & > th.sorting_desc,
        & > th.sorting_asc {
          background: $bg_shade3;
          color: $txt_shade3;
        }

        & > th.sorting_desc,
        & > th.sorting_asc {
          font-weight: bold;
        }
      }
    }
  }

  .datatable-scroll-lg, .datatable .datatable-scroll, .datatable .datatable-scroll-sm {
    overflow-x: auto;
  }

  .row-checkbox .checker,
  .row-checkbox .white {
    border-radius: 2px; /* wit steekt uit in de hoekjes */
  }
}

/* ***** CONTENT WINDOW ***** */
.content-window .content-window-body {
  background-color: $bg_shade5;
}

select {
  >option[selected] {
    background: $bg_shade6;
  }
}

